<template>
  <div class="flex h-full flex-col justify-center py-1.5 text-xs">
    <div class="flex flex-row justify-end leading-4" v-if="updatedPrice">${{ updatedPrice }}</div>
    <div class="flex flex-row justify-end leading-4 text-gray-400">
      {{ formatNumber(params.data?.amount) }}
      {{ ticker }}
    </div>
  </div>
</template>

<script setup>
import { formatNumber } from '@/composeables/filters';
import { useStore } from 'vuex';
import { computed } from 'vue';

const store = useStore();

const props = defineProps({
  params: Object
});

const ticker = computed(() => {
  const coin = store.getters.coins.find(c => c.uid == props.params.data?.coin_uid);
  return coin ? coin.ticker : '';
});
const updatedPrice = computed(() => {
  return formatNumber(props.params.data?.[`amount_usd${props.params.historical ? props.params.historical : ''}`]);
});
</script>
